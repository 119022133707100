// src/components/PrivateRoute.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HowItWorks from '../components/HowItWorks/HowItWorks';
import AssetSliceToken from '../components/AssetSliceToken/AssetSliceToken';
import HomePage from '../components/HomePage/HomePage';

const AppRoutes: React.FC = () => {
//   const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/assetslice-token" element={<AssetSliceToken />} />
            </Routes>
        </Router>
  )
};

export default AppRoutes;
