// app/pages/Marketplace.tsx

import React from 'react';
import styles from './HowItWorks.module.css';

const HowItWorks: React.FC = () => {
  return (

    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.mainContent}>
                <h2>HowItWorks</h2>
            <p>Welcome to the HowItWorks!</p>
        </div>
      </div>
    </div>

  );
};

export default HowItWorks;
