// src/components/FAQSection.js
import React, { useState } from 'react';
import styles from './FAQSection.module.css';
import { FaPlus, FaMinus } from 'react-icons/fa';

const FAQSection = () => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const faqData = [
    { question: "What is fractional ownership in real estate?", answer: "Fractional ownership allows multiple investors to own a share of a property, making it more affordable to invest in high-value real estate." },
    { question: "How does blockchain technology secure my investment?", answer: "Blockchain provides a transparent and immutable record of all transactions, ensuring the security and authenticity of your investment." },
    { question: "What kind of returns can I expect?", answer: "Returns vary based on property performance, but fractional owners earn a share of rental income and any appreciation in property value." },
    { question: "How can I get started with AssetSlices?", answer: "Sign up on our platform, explore available properties, and start investing in your chosen assets." },
    { question: "Is there a minimum investment amount?", answer: "Yes, the minimum investment amount varies by property. Please check the details of each listing for specific information." },
    { question: "Can I sell my tokens?", answer: "Yes, you can sell your tokens on our platform, providing liquidity to your investment." }
  ];

  const toggleFAQ = (index) => {
    setExpandedQuestion(expandedQuestion === index ? null : index);
  };

  return (
    <div className={styles.faqContainer}>
      <h2 className={styles.faqTitle}>Frequently Asked Questions</h2>
      {faqData.map((item, index) => (
        <div key={index} className={styles.faqItem}>
          <button className={styles.faqQuestion} onClick={() => toggleFAQ(index)}>
            {expandedQuestion === index ? <FaMinus /> : <FaPlus />}
            {item.question}
          </button>
          {expandedQuestion === index && <p className={styles.faqAnswer}>{item.answer}</p>}
        </div>
      ))}
    </div>
  );
};

export default FAQSection;
