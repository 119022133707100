// src/components/Banner.tsx
import React from 'react';
import styles from './Banner.module.css';
import assetImage1 from '../../../../assets/banner-image.webp'; // Replace with your asset image

const Banner = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.imageContainer}>
        <img src={assetImage1} alt="Banner Image" className={styles.bannerImage} />
        <div className={styles.textOverlay}>
          {/* <h1 className={styles.overlayText}>Your Gateway to Investing in Dubai's Real Estate</h1>
          <p className={styles.overlayText}>Invest easily and securely with fractional ownership in real estate through blockchain technology.</p> */}
        </div>
      </div>
    </div>
  );
};

export default Banner;
