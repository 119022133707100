// src/HomePage.js
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import styles from './HomePage.module.css';
import assetImage1 from '../../../assets/1.jpg'; // Add a few images for the carousel
import assetImage2 from '../../../assets/2.jpg';
import assetImage3 from '../../../assets/3.jpg';
import logo from '../../../assets/logo.png'; // Import your logo image
import InvestInDubai from './InvestInDubai/InvestInDubai';  // Import the new component
import WhyChooseUs from './WhyChooseUs/WhyChooseUs';  // Import the new component
import FAQ from './FAQ/FAQ';
import Banner from './Banner/Banner';
import FeaturedListings from './FeaturedListings/FeaturedListings';
import FAQSection from './FAQ/FAQSection';

const HomePage = () => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const handleQuestionClick = (index: any) => {
    setExpandedQuestion(expandedQuestion === index ? null : index);
  };

  const faqData = [
    { question: "What is fractional ownership in real estate?", answer: "Fractional ownership allows multiple investors to own a share of a property, making it more affordable to invest in high-value real estate." },
    { question: "How does blockchain technology secure my investment?", answer: "Blockchain provides a transparent and immutable record of all transactions, ensuring the security and authenticity of your investment." },
    { question: "What kind of returns can I expect?", answer: "Returns vary based on property performance, but fractional owners earn a share of rental income and any appreciation in property value." },
    { question: "How can I get started with AssetSlices?", answer: "Sign up on our platform, explore available properties, and start investing in your chosen assets." },
    { question: "Is there a minimum investment amount?", answer: "Yes, the minimum investment amount varies by property. Please check the details of each listing for specific information." },
    { question: "Can I sell my tokens?", answer: "Yes, you can sell your tokens on our platform, providing liquidity to your investment." }
  ];

  return (
    <div className={styles.homepageContainer}>
      <header className={styles.header}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="AssetSlices Logo" className={styles.logo} />
          {/* <h1 className={styles.logo}></h1> */}
        </div>
        <div className={styles.headerRight}>
          <Link to="/assetslice-token" className={styles.headerLink}>AssetSlice Token</Link>
          <Link to="/how-it-works" className={styles.headerLink}>How it works</Link>
          {/* <Link to="/login" className={styles.signInLink}>Sign In</Link> */}
        </div>
      </header>

      <Banner />

      <section className={styles.introSection}>
        <h2>Welcome to AssetSlices</h2>
        <p>
          At AssetSlices, we offer a revolutionary way to invest in Dubai's booming real estate market.
          With blockchain technology, you can own tokens representing your investments and earn returns based on the performance of the properties.
        </p>
        <p className={styles.tagline}>Revolutionizing Real Estate Investments in Dubai</p>
      </section>

      <InvestInDubai />
      <FAQSection />
      <WhyChooseUs />
      <FeaturedListings />

      <section className={styles.ctaSection}>
        <h2>Get Started Today</h2>
        <p>Join us and start investing in the future of Dubai's real estate market.</p>
        <Link to="/register">
          <button className={styles.signUpButton}>Sign Up Now</button>
        </Link>
      </section>


      <footer className={styles.footer}>
        <div className={styles.footerContent}>
          <p>&copy; 2024 AssetSlices. All rights reserved.</p>
          <div className={styles.footerLinks}>
            <Link to="/faq">FAQ</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/cookie-policy">Cookie Policy</Link>
          </div>
          <div className={styles.socialMedia}>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
