// src/components/WhyChooseUs.js
import React from 'react';
import styles from './WhyChooseUs.module.css';  // Make sure you have corresponding CSS

const WhyChooseUs = () => {
  return (
    <section className={styles.featuresSection}>
      <h2>Why Choose AssetSlices?</h2>
      <div className={styles.features}>
        <div className={styles.feature}>
          <h3>Partial Investments</h3>
          <p>Invest in real estate with a fraction of the cost and own a part of premium properties in Dubai.</p>
        </div>
        <div className={styles.feature}>
          <h3>Blockchain Technology</h3>
          <p>Secure and transparent transactions ensured by blockchain technology.</p>
        </div>
        <div className={styles.feature}>
          <h3>Earn ROI</h3>
          <p>Receive returns on your investments based on property performance.</p>
        </div>
        <div className={styles.feature}>
          <h3>Diverse Portfolio</h3>
          <p>Diversify your investments across various high-yield properties.</p>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
