// App.tsx

import React from 'react';
import AppRoutes from './routes/Routes';

const App: React.FC = () => {
  return (
    <>
    <AppRoutes />
    </>
  );
};

export default App;
