// app/pages/Marketplace.tsx

import React from 'react';
import styles from './AssetSliceToken.module.css';

const AssetSliceToken: React.FC = () => {
  return (

    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.mainContent}>
                <h2>AssetSliceToken</h2>
            <p>Welcome to the AssetSliceToken!</p>
        </div>
      </div>
    </div>

  );
};

export default AssetSliceToken;
