import React from 'react';
import styles from './FeaturedListings.module.css';
import house1 from '../../../../assets/11.jpg';
import house2 from '../../../../assets/12.jpg';
import house3 from '../../../../assets/13.jpg';

const FeaturedListings = () => {
  const listings = [
    {
      image: house1,
      title: 'Awesome Family Home',
      description: '1 bed | 1 bath | 1200 sq ft',
      price: '$450,000',
      badges: ['BUY', 'RENT']
    },
    {
      image: house2,
      title: 'Contemporary Studio',
      description: '1 bed | 1 bath | 1300 sq ft',
      price: '$250,000',
      badges: ['BUY', 'RENT']
    },
    {
      image: house3,
      title: 'Studio Bay View',
      description: '1 bed | 1 bath | 4400 sq ft',
      price: '$180,000',
      badges: ['BUY']
    }
  ];

  return (
    <div className={styles.featuredListings}>
      <h2>Discover Our Featured Listings</h2>
      <p>These featured listings contain exclusive real estate opportunities within the city</p>
      <div className={styles.carousel}>
        {listings.map((listing, index) => (
          <div key={index} className={styles.listingCard}>
            <div className={styles.imageContainer}>
              <img src={listing.image} alt={listing.title} />
              {listing.badges.map((badge, idx) => (
                <div key={idx} className={styles.badge}>{badge}</div>
              ))}
            </div>
            <div className={styles.listingInfo}>
              <h3>{listing.title}</h3>
              <p>{listing.description}</p>
              <p className={styles.price}>{listing.price}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedListings;
