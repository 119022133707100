import React from 'react';
import { FaTaxi, FaBuilding, FaGlobeAsia, FaRegHandshake, FaCity } from 'react-icons/fa';
import styles from './InvestInDubai.module.css';

const InvestInDubai = () => {
  return (
    <section className={styles.investSection}>
      <h2 className={styles.investHeading}>Why Invest in Dubai Real Estate?</h2>
      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <FaTaxi className={styles.icon} />
          <h3>Tax-Free Income</h3>
          <p>Dubai offers a tax-free environment for property investors, maximizing your returns.</p>
        </div>
        <div className={styles.card}>
          <FaBuilding className={styles.icon} />
          <h3>High Rental Yields</h3>
          <p>Enjoy some of the highest rental yields in the world compared to other major cities.</p>
        </div>
        <div className={styles.card}>
          <FaGlobeAsia className={styles.icon} />
          <h3>Strategic Location</h3>
          <p>Dubai's strategic location makes it a key player in international real estate markets.</p>
        </div>
        <div className={styles.card}>
          <FaRegHandshake className={styles.icon} />
          <h3>Robust Infrastructure</h3>
          <p>World-class infrastructure supports real estate investment and development.</p>
        </div>
        <div className={styles.card}>
          <FaCity className={styles.icon} />
          <h3>Stable Investment Climate</h3>
          <p>Benefit from Dubai's growing economy and stable investment environment.</p>
        </div>
      </div>
    </section>
  );
};

export default InvestInDubai;
